



















































import SearchCard from '@/views/taint/searchCard.vue'
import { Component, Prop } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import merge from 'webpack-merge'
import { GraphData } from './types/search'
import Dagre from '@/components/G6/Dagre.vue'

@Component({
  name: 'PoolDetail',
  components: {
    SearchCard,
    Dagre,
  },
})
export default class PoolDetail extends VueBase {
  @Prop() id!: any
  private targetId = this.id || this.$route.params.id
  private activeName = 'flowDebug'
  private info: any = null
  private graphData: GraphData = {
    nodes: [],
    edges: [],
  }
  private dependencies = []
  private changeActive(e: any) {
    this.$router.replace({
      query: merge(this.$route.query, { activeName: e.name }) as any,
    })
  }
  private async getSca() {
    const res = await this.services.taint.sca({
      method_pool_id: this.targetId,
    })
    if (res.status === 201) {
      this.dependencies = res.data
    }
  }
  private async getList() {
    const res: any = await this.services.taint.search({
      id: this.targetId,
    })
    const tableList = res.data.method_pools.map((item: any, index: number) => {
      const vulnerablities_count_map = {}
      const relations_map = {}
      res.data.aggregation.vulnerablities_count.forEach((i: any) => {
        vulnerablities_count_map[i.method_pool_id] = i
      })

      res.data.relations.forEach((i: any) => {
        relations_map[i.method_pool_id] = i
      })
      return {
        method_pools: item,
        vulnerablities_count: vulnerablities_count_map[item.id],
        relations: relations_map[item.id],
      }
    })
    this.info = tableList[0]
  }

  private async getMethodPool() {
    const res = await this.services.taint.graph({
      method_pool_id: this.targetId,
      method_pool_type: 'normal',
    })
    if (res.status === 201) {
      this.graphData = res.data
    }
  }

  mounted() {
    this.getList()
    this.getMethodPool()
    // this.getSca()
  }
}
